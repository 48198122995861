/**
 * Calculates the number of whole days between two given dates.
 * @param startDate - The starting date.
 * @param endDate - The ending date.
 * @returns The number of whole days between the start and end dates.
 */
export const getDaysBetweenDates = (startDate: Date, endDate: Date): number => {
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    // Convert the time difference from milliseconds to hours rounds up to the next whole day. Otherwise, it rounds down to the previous whole day.
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    // Return the result
    return hoursDifference < 24 || hoursDifference % 24 >= 1
    ? Math.ceil(hoursDifference / 24)
    : Math.floor(hoursDifference / 24);
  };

/**
 * Combines a date with a time string to create a new Date object representing the full date and time.
 *
 * @param {Date} date - The date to which the time will be added.
 * @param {string} time - The time string in the format "HH:MM" to be combined with the date.
 * @returns {Date} A new Date object representing the full date and time.
 */
export const combineDateAndTime = (date: Date, time: string): Date => {
  // Split the time string by colon to separate hours and minutes
  const parsedTime = time.split(":").map((v) => Number(v));
  // Extract hours and minutes from the parsed time array
  const hours = parsedTime[0];
  const minutes = parsedTime[1];
  // Create a new Date object based on the provided date
  const fullDate = new Date(date);
  // Set the hours and minutes to the new Date object
  fullDate.setHours(hours, minutes);
  // Return the updated Date object
  return fullDate;
};

/**
 * Converts the provided date object to its UTC equivalent. Optionally clones the date object.
 * By default, the date object is cloned to avoid mutating the original date.
 * If the clone parameter is set to false, the original date object will be mutated to UTC time.
 *
 * @param {Date} date - The date object to be converted to UTC.
 * @param {boolean} [clone=true] - A boolean flag that determines whether to clone the date object.
 * @returns {Date} The UTC equivalent of the original date, either as a new Date object or the mutated original.
 */
export function toUTCDate(date: Date, clone: boolean = true): Date {
  const resultDate = clone ? new Date(date) : date;
  resultDate.setTime(resultDate.getTime() - (resultDate.getTimezoneOffset() * 60 * 1000));
  return resultDate;
}

/**
 * convert utc time like 14:00 to time: 16:00
 * @param time 
 * @returns 
 */
export function fromUTCTimeToTime(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date();

  // Set the UTC hours and minutes for the date object
  date.setUTCHours(hours, minutes);

  // Extract local hours and minutes, format, and convert back to numbers
  return [
    date.getHours().toString().padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
  ];
}
