import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/update-settings/update-settings.module').then(
        ({ UpdateSettingsModule }) => UpdateSettingsModule
      ),
  },
  {
    path: 'manual-notifications',
    loadChildren: () =>
      import('./modules/manual-notifications/manual-notifications.module').then(
        ({ ManualNotificationsModule }) => ManualNotificationsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
