import { inject, Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ELocalizationLang } from "../enums";
import { LocalStorageService } from "src/app/kernel/tools/services/local-storage.service";
import { IUser } from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private localStorageService = inject(LocalStorageService);

  readonly currentLang$ = new Subject<ELocalizationLang>();

  changeLang(lang: ELocalizationLang) {
    const _lang = this.localStorageService.get<ELocalizationLang>('lang');
    const user = this.localStorageService.get<IUser>('user');
    if(user?.preferredLang !== _lang) {
      this.localStorageService.set('lang', lang);
      this.currentLang$.next(lang);
    }
  }
}