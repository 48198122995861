<div [class]="theme" class="dropdown">
  <span class="input-control__label" *ngIf="label">{{
    label | translate
  }}</span>
  <ng-container
    [ngTemplateOutlet]="
      multiple ? DropdownMultipleTemplate : DropdownSingleTemplate
    "
  ></ng-container>
  <sxw-error-message
    *ngIf="control && control.errors && (control.touched || sxwForm?.submitted)"
    [message]="control ? control.errors : null"
  ></sxw-error-message>
</div>

<ng-template #DropdownSingleTemplate>
  <div [ngClass]="{'readonly': readonly, 'with-icon': icon}">
    <div class="dropdown-icon" *ngIf="icon">
      <sxw-svg-icon [width]="icon.width ?? '20px'"
                    [height]="icon.height ?? '20px'" [fillColor]="icon.fillColor ?? 'black'" [name]="icon.name">

      </sxw-svg-icon>
    </div>
    <p-dropdown
      optionLabel="__optionLabel"
      optionGroupChildren="__children"
      optionGroupLabel="__optionLabel"
      [group]="group"
      [appendTo]="appendTo"
      [overlayOptions]="{
        baseZIndex: 9999
      }"
      [virtualScroll]="virtualScroll"
      [virtualScrollItemSize]="44"
      [virtualScrollOptions]="virtualScrollOptions"
      [placeholder]="placeholder | translate"
      [emptyMessage]="emptyMessage"
      [tabindex]="0"
      [filterMatchMode]="filterMatchMode"
      [filterBy]="filterBy ? filterBy : optionLabel ? optionLabel : 'name'"
      (onFilter)="updateFilter($event)"
      (onHide)="onTouched();onOverlayClosedEvent.emit()"
      (onClear)="onClearHandler($event)"
      [showClear]="showClear"
      [filter]="isFilter"
      [styleClass]="styleClass"
      [options]="options"
      [optionDisabled]="optionDisabled"
      [ngClass]="{
        'invalid-input':
          control && control.errors && (control.touched || sxwForm?.submitted)
      }"
      (onChange)="onChangeHandler($event)"
      filterPlaceholder="{{ 'Search' | translate }}"
      [autoDisplayFirst]="autoDisplayFirst"
      [(ngModel)]="value"
      [dropdownIcon]="dropdownIcon"
      [ngStyle]="{
        'p-dropdown-trigger': 'url(/assets/images/icons/magnifying-glass.svg)',
        '--filter-input-icon': 'url(/assets/images/icons/magnifying-glass.svg)'
      }"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      (onShow)="changePanelState(true)"
      (onHide)="changePanelState(false);onOverlayClosedEvent.emit()"
      panelStyleClass="{{'custom-dropdown-panel'}}"
    >
      <ng-template #item pTemplate="item" let-item>
        <ng-container
          *ngIf="content"
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
        <span class="dropdown-item-text" *ngIf="!content" [title]="(item['__optionLabelAsync'] | async)">
          <i *ngIf="optionIcon" class="icon" [class]="item?.[optionIcon]"></i>
          {{ item?.["__optionLabelAsync"] | async }}
        </span>
      </ng-template>
      <ng-template #selectedItem pTemplate="selectedItem" let-item>
        <ng-container
          *ngIf="selectedContent"
          [ngTemplateOutlet]="selectedContent"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
        <span *ngIf="!selectedContent">
          <i *ngIf="optionIcon" class="icon" [class]="item?.[optionIcon]"></i>
          {{ item?.["__optionLabelAsync"] | async }}
        </span>
      </ng-template>
    </p-dropdown>
  </div>
</ng-template>

<ng-template #DropdownMultipleTemplate>
  <div [ngClass]="{'readonly': readonly}">
    <p-multiSelect
      #pMultiSelect
      optionLabel="__optionLabel"
      optionGroupChildren="__children"
      optionGroupLabel="__optionLabel"
      [group]="group"
      [appendTo]="appendTo"
      [overlayOptions]="{
        baseZIndex: 9999
    }"
      [virtualScroll]="virtualScroll"
      [virtualScrollItemSize]="44"
      [virtualScrollOptions]="virtualScrollOptions"
      [placeholder]="placeholder | translate"
      [options]="options"
      [emptyMessage]="emptyMessage"
      [showClear]="showClear"
      [filter]="false"
      [filterBy]="filterBy ? filterBy : optionLabel ? optionLabel : 'name'"
      (onChange)="onChangeHandler($event)"
      (onPanelHide)="onTouched()"
      (onClear)="onChangeHandler({ value: null }); this.onTouched()"
      [styleClass]="styleClass"
      [optionDisabled]="optionDisabled"
      [ngClass]="{
      'invalid-input':
        control && control.errors && (control.touched || sxwForm?.submitted)
    }"
      [display]="multiSelectLabelDisplay"
      [(ngModel)]="value"
      [style]="{
      '--dropdown-icon': 'url(/assets/images/icons/down.svg)',
      '--filter-input-icon': 'url(/assets/images/icons/magnifying-glass.svg)'
    }"
      [required]="required"
      [readonly]="readonly"
      [disabled]="disabled"
      panelStyleClass="{{'custom-multi-dropdown'}}"
    >
    <!-- This custom template in multiSelect only because when run query on filter on multiSelect component, options doesn't render correctly -->
    <ng-template pTemplate="header" *ngIf="isFilter">
      <div class="p-multiselect-filter-container">
        <input type="text" [(ngModel)]="_filterTerm" (input)="updateFilter($event)"
          class="p-multiselect-filter p-inputtext p-component"
        />
        <img src="/assets/images/icons/magnifying-glass.svg" alt="magnifying-glass">
      </div>
    </ng-template>

      <ng-template pTemplate="item" let-item>
        <ng-container
          *ngIf="content"
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
        <span *ngIf="!content">
        <i *ngIf="optionIcon" class="icon" [class]="item?.[optionIcon]"></i>
          {{ item?.["__optionLabelAsync"] | async }}
      </span>
      </ng-template>
      <ng-template pTemplate="selectedItems" let-items>
        <div class="p-multiselect-token" *ngFor="let item of items">
        <span class="p-multiselect-token-label">
          <ng-container
            *ngIf="selectedContent"
            [ngTemplateOutlet]="selectedContent"
            [ngTemplateOutletContext]="{ $implicit: item }"
          >
          </ng-container>
          <span *ngIf="!selectedContent">
            <i *ngIf="optionIcon" class="icon" [class]="item?.[optionIcon]"></i>
            {{ item?.["__optionLabelAsync"] | async }}
          </span>
        </span>
          <span
            *ngIf="!(disabled || readonly)"
            class="p-multiselect-token-icon pi pi-times-circle"
            (click)="removeChip(item, $event)"
          ></span>
        </div>
        <div *ngIf="!items?.length">
          {{ placeholder | translate }}
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
</ng-template>
