import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const settingsSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'setting',
    label: 'setting',
    routerLink: '/settings',
    permissions: ['UPDATE_SETTINGS_CONFIG'],
  },
  {
    id: 'manual-notifications',
    label: 'manual-notifications',
    routerLink: '/manual-notifications',
    permissions: ['VIEW_MANUAL_NOTIFICATION'],
  },
];
